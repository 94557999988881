import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Hero from "../components/home/hero";
import Slider from "../components/home/slider";
import Onboarding from "../components/home/onboarding";
import FrequentlyQuestions from "../components/home/frequentlyQuestions";

function Home() {

  const { hash } = useLocation(); 

  useEffect(() => {
    if (hash) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" }); 
      }
    }
  }, [hash]); 

  return (
    <div>
      <Hero />
      <Slider/>
      <div id="onboarding">
        <Onboarding />
      </div>
      {/* <div id="blogComp">
        <BlogsComponent />
      </div> */}
      <div id="contactUs">
      <FrequentlyQuestions/>
      </div>
    </div>
  );
}


export default Home;
