import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import { FaCamera, FaPlusCircle } from "react-icons/fa";
import ReactQuill from "react-quill";
import { doc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../firebaseConfig";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "react-quill/dist/quill.snow.css";
import Notification from "../../components/pop_up/notification";

const EditBlogs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const blogData = location.state?.blog; 
  const [notification, setNotification] = useState(null);

  const [title, setTitle] = useState(blogData?.title || "");
  const [description, setDescription] = useState(blogData?.description || "");
  const [longDescription, setLongDescription] = useState(blogData?.longDescription || "");
  const [image, setImage] = useState(blogData?.blogImage || null);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const storageRef = ref(storage, `mainImages/${Date.now()}-${file.name}`);
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        setImage(downloadURL); // Update the state with the uploaded image URL
      } catch (error) {
        console.error("Error uploading image:", error);
        setNotification("Failed to upload the image. Please try again.");
      }
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setIsUpdating(true);

    const blogRef = doc(db, "blogs", blogData.id);

    try {
      await updateDoc(blogRef, {
        title,
        description,
        longDescription,
        blogImage: image,
      });
      setNotification("Blog updated successfully!");
      setTimeout(() => navigate('/admin/blogManagement'), 2000);
    } catch (error) {
      console.error("Error updating blog:", error);
      setNotification("Failed to update the blog. Please try again.");
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="idea-submission-wrapper">
   <style jsx="true">{`
        .idea-submission-wrapper {
          max-width: 1000px;
          margin-left: 242px;
          padding: 20px;
        }
        @media (max-width: 900px) {
          .idea-submission-wrapper {
            margin-left: 2px;
            margin-right: 2px;
          }
        }
        .form-container {
          background: #fff;
          width: 100%;
          padding: 30px;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        .form-title {
          text-align: center;
          margin-bottom: 20px;
          font-weight: 700;
        }

        .upload-photo {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background: #e9ecef;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          position: relative;
          cursor: pointer;
        }

        .upload-photo img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }

        .upload-photo .camera-icon {
          font-size: 30px;
          color: #6c757d;
        }

        .upload-photo .plus-icon {
          position: absolute;
          top: -8px;
          right: -8px;
          font-size: 18px;
          color: #c69e59;
        }

        .upload-photo-text {
          display: block;
          text-align: center;
          margin-top: 5px;
          color: #6c757d;
        }

        .form-label {
          font-weight: 500;
          margin-bottom: 6px;
          display: block;
          text-align: left;
        }

        .form-control,
        .form-select {
          border-radius: 8px;
        }

        .save-button {
          width: 200px;
          height: 40px;
          background: #c69e59;
          border: none;
          border-radius: 28px;
          transition: background 0.3s ease;
          margin: 20px auto 0;
          display: block;
        }

        .save-button:hover {
          background: #a88247;
        }
      `}</style>
      <Container>
        <Row className="justify-content-center">
          <div className="form-container">
            <h3 className="form-title">Edit Blog</h3>
            <Form onSubmit={handleSave}>
              <div className="mb-3 text-center">
                <div className="upload-photo">
                  {image ? (
                    <img src={image} alt="Blog" />
                  ) : (
                    <>
                      <FaCamera className="camera-icon" />
                      <FaPlusCircle className="plus-icon" />
                    </>
                  )}
                  <input
                    type="file"
                    style={{
                      position: "absolute",
                      width: "100px",
                      height: "100px",
                      opacity: 0,
                      cursor: "pointer",
                    }}
                    onChange={handleImageChange}
                  />
                </div>
                <small className="upload-photo-text">Upload Photo</small>
              </div>

              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group>
                    <Form.Label className="form-label">Title</Form.Label>
                    <Form.Control
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="A short, catchy title for the blog."
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-3">
                <Form.Label className="form-label">Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="A quick summary of the blog (up to 250 characters)."
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="form-label">Long Description</Form.Label>
                <ReactQuill
                  value={longDescription}
                  onChange={setLongDescription}
                  theme="snow"
                  placeholder="Write your long description here..."
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }, { font: [] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["bold", "italic", "underline"],
                      [{ align: [] }],
                      ["link"],
                      [{ color: [] }, { background: [] }],
                      ["image"],
                    ],
                  }}
                  formats={[
                    "header",
                    "font",
                    "list",
                    "bold",
                    "italic",
                    "underline",
                    "align",
                    "color",
                    "background",
                    "link",
                    "image",
                  ]}
                />
              </Form.Group>

              <Button type="submit" className="save-button" disabled={isUpdating}>
                {isUpdating ? "Processing..." : "Save"}
              </Button>
            </Form>
          </div>
        </Row>
      </Container>
      {notification && (
        <Notification
          text={notification}
          onClose={() => setNotification(null)}
        />
      )}
    </div>
  );
};

export default EditBlogs;
