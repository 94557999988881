import React from "react";
import BlogsComponent from "../components/blog/blog";

function Blog() {

  return (
    <div>
     <BlogsComponent />
    </div>
  );
}


export default Blog;
