import { fetchCollectionData } from "../../../services/retriveDataService"; 

export const fetchBlogs = async () => {
  try {
    const blogs = await fetchCollectionData("blogs");
    return blogs;
  } catch (error) {
    console.error("Error fetching blogs:", error);
    throw new Error("Failed to fetch blogs. Please check the console for more details.");
  }
};
