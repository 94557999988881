import React, { useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation } from "react-router-dom";

const BlogDetails = () => {
  const { state } = useLocation();
  const blog = state?.blog || {};

  // Format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-GB", options); // Example: 25 December 2024
  };

  useEffect(() => {
    const longDescriptionElement = document.getElementById("long-description-content");
    if (longDescriptionElement) {
      // Style images inside the long description
      const images = longDescriptionElement.getElementsByTagName("img");
      Array.from(images).forEach((img) => {
        img.style.width = "90%";
        img.style.height = "400px";
        img.style.borderRadius = "10px";
        img.style.marginTop = "20px";
        img.style.display = "block"; 
        img.style.marginLeft = "auto";
        img.style.marginRight = "auto";
      });

      // Apply text alignment styles to the long description
      longDescriptionElement.style.textAlign = "justify";
      longDescriptionElement.style.textAlignLast = "left";
    }
  }, [blog.longDescription]); // Re-run when longDescription changes

  return (
    <div className="pt-2">
      <Image
        src={blog.blogImage || "/images/default-blog.png"}
        alt="Top Banner"
        style={{ width: "90%", borderRadius: "10px", height: "400px" }}
      />

      <Container className="mt-5 mb-5">
        <h1 className="text-start fw-bold">{blog.title}</h1>
        <Row className="mt-4 mb-4 align-items-center">
          <Col xs={12} md={6} lg={6} className="d-flex align-items-center">
            <Image
              src="/images/aboutMe.png"
              alt="Author"
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                marginRight: "10px",
              }}
            />
            <p className="mb-0">
              <strong>Author Name:</strong> Dr. Emily Walker
            </p>
          </Col>
          <Col xs={12} md={6} className="text-md-end text-start">
            <p className="mb-0">
              <strong>Publication Date:</strong> {formatDate(blog.createdAt)}
            </p>
          </Col>
        </Row>
        <section className="mt-4">
          <h3 className="text-start fw-semibold">Overview</h3>
          <p className="text-start">{blog.description}</p>
        </section>

        <section className="mt-4">
          <h3 className="text-start fw-semibold">Our Approach</h3>
          <div
            id="long-description-content"
            dangerouslySetInnerHTML={{ __html: blog.longDescription }}
          />
        </section>
      </Container>
    </div>
  );
};

export default BlogDetails;
