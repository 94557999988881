import React, { useState } from 'react';
import { Accordion, Container } from 'react-bootstrap';

const FrequentlyQuestions = () => {
  const [activeKey, setActiveKey] = useState(null);

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  return (
    <>
      <style>
        {`
          .faq-container {
            margin-top: 3rem;
            margin-bottom: 3rem;
          }

          .faq-title {
            text-align: left;
            color: #c69e59;
            font-weight: bold;
            font-size: 2rem;
            margin-bottom: 2rem;
          }

          .faq-card {
            border: none;
            margin-bottom: 1.5rem;
            border-radius: 8px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
          }

          .faq-header {
            font-weight: bold;
            font-size: 1.2rem;
            padding: 1rem;
            cursor: pointer;
            border-radius: 8px;
            transition: background-color 0.3s ease, color 0.3s ease;
            text-align: left;
          }

          .faq-header-closed {
            background-color: #f8f3e3;
            color: #333;
          }

          .faq-header-open {
            background-color: #d6d6d6;
            color: #333;
          }

          .faq-header:hover {
            background-color: #e0e0e0;
          }

          .faq-body {
            color: #444;
            font-size: 1rem;
            padding: 1.25rem;
            background-color: #fff;
            border-top: 1px solid #e0e0e0;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            line-height: 1.6;
            text-align: left;
          }

          .faq-body p {
            margin-bottom: 1rem;
          }

          .faq-body ul {
            margin-left: 1.5rem;
          }

          .faq-body ul li {
            margin-bottom: 0.5rem;
          }

          .faq-body strong {
            font-weight: bold;
            color: #333;
          }

          .faq-body a {
            color: #c69e59;
            text-decoration: underline;
          }
        `}
      </style>

      <Container className="faq-container">
        <h2 className="faq-title">Frequently Asked Questions</h2>

        <Accordion activeKey={activeKey} onSelect={handleToggle}>
          <Accordion.Item eventKey="0" className="faq-card">
            <Accordion.Header
              className={`faq-header ${activeKey === '0' ? 'faq-header-open' : 'faq-header-closed'}`}
            >
              Plug-and-Play Business Model - TL Enterprise Institute
            </Accordion.Header>
            <Accordion.Body className="faq-body">
              <p>
              The Plug-and-Play Business Model from TL Enterprise Institute is designed to make entrepreneurship simple and accessible. The process begins when you submit your information and purchase a ticket on our website. After your purchase, you’ll receive a carefully crafted business idea tailored to market trends and profit potential. Each idea is unique and ready to implement, so you can start building your future right away.
              Once you receive the idea, you face an important choice. If the business idea perfectly aligns with your vision, you can accept it and start working on it independently. If it doesn’t fully meet your expectations, you’re free to decline it without any obligations. If you opt for collaboration, the concept will be shared with three other entrepreneurs from our network.
              They will have the opportunity to pitch their approach to you, allowing you to choose the perfect partner for your venture.
              </p>
              <p>
              In the case of collaboration, you’ll gain access to our expertise and guidance to further develop the idea together. Our team is available to support you throughout the process. In return, TL Enterprise takes a small equity stake, which is negotiable depending on the level of support you require from us and our team. This model is ideal for both newcomers and seasoned entrepreneurs, lowering the barriers and delivering immediate value.
              </p>
              <p>
              With <strong>TL Enterprise Institute,</strong> you don’t need to spend weeks brainstorming ideas or building strategies—we’ve already done the groundwork for you. All you need is your dedication and work ethic to turn the idea into a success. Entrepreneurship isn’t just easier—it’s smarter.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1" className="faq-card">
            <Accordion.Header
              className={`faq-header ${activeKey === '1' ? 'faq-header-open' : 'faq-header-closed'}`}
            >
              Benefits of the Plug-and-Play Business Model
            </Accordion.Header>
            <Accordion.Body className="faq-body">
              <p>
              The Plug-and-Play Business Model offers a seamless way to step into entrepreneurship. By providing fully developed business ideas, it eliminates the time and stress of starting from scratch. You gain access to market-ready opportunities, crafted to maximize profitability and align with current trends. Whether you’re a beginner or an experienced entrepreneur, the model adapts to your needs. If you choose to collaborate, you’ll benefit from TL Enterprise’s expert guidance and a network of like-minded entrepreneurs.
              This collaborative approach allows you to build a strong foundation with the support of professionals. You also retain the flexibility to customize your journey—work independently or partner with others to amplify your results. The model minimizes risk by streamlining the startup process, so you can focus on growth instead of trial and error. With this system, success becomes more attainable and efficient. The result? A faster, smarter, and more rewarding entrepreneurial journey.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2" className="faq-card">
            <Accordion.Header
              className={`faq-header ${activeKey === '2' ? 'faq-header-open' : 'faq-header-closed'}`}
            >
              How to Get Started with TL Enterprise Institute?
            </Accordion.Header>
            <Accordion.Body className="faq-body">
              <ol>
                <li><strong>Submit Your Information:</strong> Fill out your details and purchase a ticket on our website.</li>
                <li><strong>Receive Your Business Idea:</strong> A tailored concept will be delivered to you.</li>
                <li><strong>Decide Your Next Step:</strong> Accept, decline, or collaborate on the idea provided.</li>
                <li><strong>Launch and Grow:</strong> Use provided resources to build and scale effectively.</li>
              </ol>
              <p>
                <strong>Why Choose TL Enterprise Institute?</strong> We provide a quick, affordable, and low-risk approach to starting your business with expert guidance every step of the way.
              </p>
              <p>
                Visit our website at <a href="https://tlenterpriseinstitute.com" target="blank">https://tlenterpriseinstitute.com</a> for more information.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </>
  );
};

export default FrequentlyQuestions;
