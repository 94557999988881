import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useParams } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { auth, functions, db } from '../firebaseConfig';
import { getDoc, doc } from 'firebase/firestore';

const PaymentPage = () => {
  const stripe = useStripe();
  const elements = useElements();

  const { userId } = useParams();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }
  }, [stripe, elements]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const user = auth.currentUser;
      if (!user) {
        setError('You must be logged in to make a payment.');
        setLoading(false);
        return;
      }

      const userDoc = await getDoc(doc(db, 'users', userId));
      if (!userDoc.exists()) {
        setError('User not found.');
        setLoading(false);
        return;
      }
      // const userData = userDoc.data();
      const price = '100';

      const createSession = httpsCallable(functions, 'createStripeCheckoutSession');
      const { data } = await createSession({
        price: price, // Make sure the price is passed in cents
        userId: userId,
        successUrl: 'http://localhost:3000/success', // Update this to your correct URL
      });

      const { error } = await stripe.redirectToCheckout({ sessionId: data.sessionId });

      if (error) {
        setError(error.message);
      }
    } catch (error) {
      setError(`Error processing payment: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="payment-page">
      <h1>Payment Page</h1>
      <form onSubmit={handleSubmit}>
        <CardElement />
        {error && <div className="error">{error}</div>}
        <button type="submit" disabled={!stripe || loading}>
          {loading ? 'Processing...' : 'Pay'}
        </button>
      </form>
    </div>
  );
};

export default PaymentPage;
