import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

const HeroAboutUs = () => {
    return (
        <>
            <Container fluid className="py-5" style={{ backgroundColor: '#f8f3e3' }}>
                <Container>
                    <Row className="text-center mb-4">
                        <Col>
                            <h1 className="fw-bold">About Us</h1>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="">
                <Container className='pt-5 mt-3'>
                    <Row>
                        <Col md={6} className="pe-5 d-flex justify-content-center align-items-start">
                            <Image
                                src="/images/aboutUs.png"
                                alt="Team Discussion"
                                width='100%'
                                height='70%'
                                className="rounded"
                            />
                        </Col>
                        <Col md={5} className="d-flex flex-column justify-content-start">
                            <h2 className="fw-bold" style={{ textAlign: 'left'}}>What Are We</h2>

                            <p style={{ textAlign: 'justify' , maxWidth:'100%'}}>
                            At <strong>TL Enterprise Institute,</strong> we believe that entrepreneurship doesn’t have to be complicated—it can be seamless, exciting, and built for success. That’s why we created the Plug-and-Play Business Model. Our mission? To take the stress and guesswork out of starting a business, so you can focus on what truly matters: building something you’re passionate about.
                            We don’t just provide business ideas; we offer opportunities. Each idea is carefully crafted, market-ready, and designed with profitability in mind. Whether you’re starting your very first venture or looking for your next challenge, our process gives you everything you need to hit the ground running.
                            But we don’t stop there. </p>
                            <p  style={{ textAlign: 'justify' , maxWidth:'100%'}}>
                            Collaboration is at the heart of what we do. When you choose to partner with us, you gain access to expert mentorship, a network of driven entrepreneurs, and the tools to bring your vision to life. And while we take a small negotiable equity stake for the support we provide, the results speak for themselves: 97% of the work is ready to go—the remaining 3% is your effort, motivation and determination.
                            </p>
                            <p style={{ textAlign: 'justify' , maxWidth:'100%'}}>
                            At <strong>TL Enterprise Institute,</strong> it’s not just about ideas; it’s about empowerment. Because for us, it’s not an obsession—it’s a passion.
                            </p>

                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
};

export default HeroAboutUs;
