import React, { useEffect, useState } from "react";
import { Table, Pagination, Spinner } from "react-bootstrap";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { fetchBlogs } from "../../controllers/admin_pannel/blogs/fetchBlogs";
import DeleteModal from "../../components/pop_up/deleteConfirmation";
import { collection, doc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import Notification from "../../components/pop_up/notification";

const AdminPannelBlogs = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedBlogId, setSelectedBlogId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [notification, setNotification] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 5;

  const handleDeleteClick = (blogId) => {
    setSelectedBlogId(blogId);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    if (!selectedBlogId) {
      setNotification("Invalid blog selected for deletion.");
      return;
    }

    setIsDeleting(true);
    try {
      const blogRef = doc(collection(db, "blogs"), selectedBlogId);
      await deleteDoc(blogRef);
      setBlogs(blogs.filter((blog) => blog.id !== selectedBlogId));
      setNotification("Blog deleted successfully!");
    } catch (error) {
      console.error("Error deleting blog:", error.message);
      setNotification("Failed to delete the blog. Please try again.");
    } finally {
      setIsDeleting(false);
      setShowDeleteModal(false);
    }
  };

  useEffect(() => {
    const getBlogs = async () => {
      try {
        const data = await fetchBlogs();
        setBlogs(data);
      } catch (err) {
        console.error("Error fetching blogs:", err);
        setError("Failed to fetch blogs.");
      } finally {
        setLoading(false);
      }
    };

    getBlogs();
  }, []);

  const handleClick = () => {
    navigate("/admin/addBlog");
  };

  const handleEditClick = (blog) => {
    navigate("/admin/editBlog", { state: { blog } });
  };

  // Pagination Calculations
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const totalPages = Math.ceil(blogs.length / blogsPerPage);

  return (
    <>
      <style type="text/css">
        {`
          .blogs-management-container {
            max-width: 1200px;
            margin: 0 auto;
            padding: 20px;
            background: #fff;
          }
          @media (min-width: 992px) {
            .blogs-management-container  {
              padding-left: 242px;
            }
          }
          @media (max-width: 991px) {
            .blogs-management-container  {
              padding-left: 8px;
              padding-right: 8px;
            }
          }
          .page-title {
            font-size: 40px;
            font-weight: bold;
            margin-bottom: 30px;
            color: #000;
          }
          .header-actions {
            margin-bottom: 20px;
            display: flex;
            justify-content: flex-start;
          }
          .add-blog-button {
            background-color: #d6a63e;
            color: #fff;
            border: none;
            max-width: 160px;
            border-radius: 4px;
            padding: 7px 12px;
          }
          .add-blog-button:hover {
            background-color: #c59532;
          }
          .blogs-table {
            margin-top: 20px;
          }
          .blogs-table th {
            font-weight: 600;
            color: #000;
          }
          .blogs-table td {
            vertical-align: middle;
          }
          .blog-title {
            font-weight: bold;
            color: #000;
          }
          .blog-description {
            font-size: 14px;
            color: #555;
          }
          .icon-button {
            background: none;
            border: none;
            font-size: 18px;
            margin-right: 10px;
            cursor: pointer;
          }
          .icon-edit,
          .icon-trash {
            color: #000;
          }
          .blog-image {
            width: 50px;
            height: 50px;
            border-radius: 8px;
            object-fit: cover;
          }
          .pagination-container {
            margin-top: 20px;
            display: flex;
            justify-content: flex-start;
          }
          .pagination-container .pagination {
            color: #c59532;
          }
          .pagination-container .page-link {
            color: #c59532;
            border: 1px solid #c59532;
            background-color: transparent;
            border-radius: 5px;
          }
          .pagination-container .page-link:hover {
            background-color: #c59532 !important;
            color: #fff !important;
          }
          .pagination-container .active .page-link {
            background-color: #c59532;
            color: #fff;
            border-color: #c59532;
          }
          .loading-text {
            text-align: center;
            color: #555;
            font-size: 18px;
            margin-top: 20px;
          }
          .error-text {
            text-align: center;
            color: red;
            font-size: 18px;
            margin-top: 20px;
          }
        `}
      </style>
      <div className="blogs-management-container">
        <h1 className="page-title">Blog Management</h1>
        <div className="header-actions">
          <button onClick={handleClick} className="add-blog-button">
            + Add Blog
          </button>
        </div>

        <div className="table-container">
          {loading ? (
            <div className="loading-text">
              <Spinner animation="border" /> Loading...
            </div>
          ) : error ? (
            <div className="error-text">{error}</div>
          ) : (
            <Table bordered hover className="blogs-table">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentBlogs.map((blog) => (
                  <tr key={blog.id}>
                    <td>
                      <img
                        src={blog.blogImage}
                        alt={blog.title}
                        className="blog-image"
                      />
                    </td>
                    <td>{blog.title}</td>
                    <td>{blog.description}</td>
                    <td>
                      <button onClick={() => handleEditClick(blog)} className="icon-button">
                        <FaEdit />
                      </button>
                      <button
                        onClick={() => handleDeleteClick(blog.id)}
                        className="icon-button"
                      >
                        <FaTrashAlt className="icon-trash" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
              <DeleteModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onConfirm={handleConfirmDelete}
                message="Are you sure you want to delete this blog?"
                deleteButtonText={isDeleting ? "Processing..." : "Delete"}
              />
            </Table>
          )}
        </div>

        <div className="pagination-container">
          <Pagination>
            <Pagination.Prev
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </Pagination.Prev>
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                className="ms-1 me-1"
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
            >
              Next
            </Pagination.Next>
          </Pagination>
        </div>
      </div>
      {notification && (
        <Notification
          text={notification}
          onClose={() => setNotification(null)}
        />
      )}
    </>
  );
};

export default AdminPannelBlogs;
