import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Container, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../../controllers/registerController';
import LinkedTermsPopUp from '../../components/pop_up/linkedTerms';
import { fetchCategories } from '../../controllers/categoriesController';
import { fetchNiches } from "../../controllers/nicheController";
import { FaCamera } from 'react-icons/fa';
import { storage } from '../../firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';


function Register() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState(''); // State to store uploaded image URL

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  // Handle checkbox state change
  const handleCheckboxChange = () => {
    const newCheckedState = !isCheckboxChecked; // Toggle checkbox state
    setIsCheckboxChecked(newCheckedState);

    // Show popup only when the checkbox is checked
    if (newCheckedState) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  };

  // Close popup
  const handleClose = () => {
    setShowPopup(false);
  };

  const [categories, setCategories] = useState([]);
  const [niches, setNiches] = useState([]);
  const [filteredNiches, setFilteredNiches] = useState([]);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    address: '',
    role: 'user',
    status: 'Awaiting for Idea',
    country: '',
    nationality: '',
    investmentRange: '',
    categoryId: '',
    niche: '',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({
    termsCheckbox: null
  });

  const navigate = useNavigate(); // Initialize useNavigate hook

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryData = await fetchCategories();
        setCategories(categoryData);

        const nicheData = await fetchNiches();
        setNiches(nicheData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    // Filter niches based on the selected category
    if (formData.category) {
      const filtered = niches.filter(
        (niche) => niche.categoryId === formData.category
      );
      setFilteredNiches(filtered);

      // If there are no valid niches after category change, clear 'niche' value
      if (filtered.length === 0) {
        setFormData((prev) => ({ ...prev, niche: "" }));
      }
    } else {
      setFilteredNiches([]);
    }
  }, [formData.category, niches]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    // Check if required fields are filled
    if (!formData.firstName) {
      isValid = false;
      errors.firstName = 'First Name is required.';
    }
    if (!formData.lastName) {
      isValid = false;
      errors.lastName = 'Last Name is required.';
    }
    if (!formData.email) {
      isValid = false;
      errors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      isValid = false;
      errors.email = 'Email is not valid.';
    }
    if (!formData.password) {
      isValid = false;
      errors.password = 'Password is required.';
    } else if (formData.password.length < 6) {
      isValid = false;
      errors.password = 'Password must be at least 6 characters long.';
    }
    if (!formData.address) {
      isValid = false;
      errors.address = 'Address is required.';
    }
    if (!formData.country) {
      isValid = false;
      errors.country = 'Country is required.';
    }
    if (!formData.nationality) {
      isValid = false;
      errors.nationality = 'Nationality is required.';
    }
    if (!formData.investmentRange) {
      isValid = false;
      errors.investmentRange = 'Investment Range is required.';
    }
    if (!formData.category) {
      isValid = false;
      errors.category = 'Category is required.';
    }
    if (!formData.niche) {
      isValid = false;
      errors.niche = 'Niche is required.';
    }
    // Checkbox validation
    if (!isCheckboxChecked) {
      isValid = false;
      errors.termsCheckbox = 'You must agree to the terms and conditions.';
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    if (!validateForm()) {
      return;
    }

    try {
      setLoading(true);
      let profileImageUrl = '';

      // Upload file to Firebase Storage if selected
      if (selectedFile) {
        const storageRef = ref(storage, `profileImages/${selectedFile.name}`);
        await uploadBytes(storageRef, selectedFile);
        profileImageUrl = await getDownloadURL(storageRef);
      }

      const selectedCategory = categories.find(
        (category) => category.id === formData.category
      );

      // Add category name and profile image URL to formData
      const updatedFormData = {
        ...formData,
        category: selectedCategory ? selectedCategory.name : '',
        profileImage: profileImageUrl, // Add image URL
      };

      await registerUser(updatedFormData);
      setSuccessMessage('Account successfully created!');
      navigate('/accountCreationPayment');
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    // Create a preview of the image
    const reader = new FileReader();
    reader.onloadend = () => {
      setProfileImageUrl(reader.result); // Set the image URL for preview
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <div className="create-account-container">
        <h2 className="text-center mb-4">Create Account</h2>
        {successMessage && <Alert variant="success">{successMessage}</Alert>}
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        <Container className="camera-icon-container">
          {profileImageUrl ? (
            <img
              src={profileImageUrl}
              alt="Profile"
              className="profile-image"
              style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '50%' }}
            />
          ) : (
            <label htmlFor="profileImageUpload">
              <FaCamera className="camera-icon" />
            </label>
          )}
          <input
            type="file"
            id="profileImageUpload"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </Container>


        <Form className="account-form" onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group controlId="firstName">
                <Form.Label className="form-label">First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your first name"
                  onChange={handleInputChange}
                  isInvalid={formErrors.firstName}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.firstName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="lastName">
                <Form.Label className="form-label">Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your last name"
                  onChange={handleInputChange}
                  isInvalid={formErrors.lastName}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="email">
                <Form.Label className="form-label">Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  onChange={handleInputChange}
                  isInvalid={formErrors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="password">
                <Form.Label className="form-label">Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your password"
                  onChange={handleInputChange}
                  isInvalid={formErrors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.password}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="address">
                <Form.Label className="form-label">Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your address"
                  onChange={handleInputChange}
                  isInvalid={formErrors.address}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.address}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="country">
                <Form.Label className="form-label">Country</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your country"
                  onChange={handleInputChange}
                  isInvalid={formErrors.country}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.country}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="nationality">
                <Form.Label className="form-label">Nationality</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your nationality"
                  onChange={handleInputChange}
                  isInvalid={formErrors.nationality}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.nationality}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="investmentRange">
                <Form.Label className="form-label">Investment Range</Form.Label>
                <Form.Control
                  as="select"
                  onChange={handleInputChange}
                  isInvalid={formErrors.investmentRange}
                >
                  <option value="">Select Investment Range</option>
                  <option value="999 - 3000 $">999 - 3000 $</option>
                  <option value="3001 - 5000 $">3001 - 5000 $</option>
                  <option value="5001 - 10000 $">5001 - 10000 $</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {formErrors.investmentRange}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label className="form-label">Category</Form.Label>
                <Form.Select
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Select a category
                  </option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label className="form-label">Niche</Form.Label>
                <Form.Select
                  name="niche"
                  value={formData.niche}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Select a niche
                  </option>
                  {filteredNiches.map((niche) => (
                    <option key={niche.id} value={niche.niche}>
                      {niche.niche}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="termsCheckbox" className="mt-3 checkbox-group">
            <Form.Check
              className="checkbox-label-spacing"
              type="checkbox"
              label="Non-competition agreement with linked terms."
              checked={isCheckboxChecked}
              isInvalid={formErrors.termsCheckbox} // Example error state
              onChange={handleCheckboxChange} // Trigger state change
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.termsCheckbox}
            </Form.Control.Feedback>

            <LinkedTermsPopUp show={showPopup} handleClose={handleClose} />
          </Form.Group>
          <Button variant="primary" type="submit" className="submit-btn" disabled={loading}>
            {loading ? 'Processing...' : 'Sign up'}
          </Button>
          <div className="text-center mt-3">
            Already have an account? <a href="/login" className="signup-link">Login</a>
          </div>
        </Form>

        <style jsx>{`
       .camera-icon-container {
        width: 70px;
        height: 70px;
        border: 1px solid black;
        position: relative;
        text-align: center;
        overflow: hidden;
        border-radius: 50%; /* Circle shape */
        display: flex;
        align-items: center;
        justify-content: center;
      }
    
      .camera-icon {
        font-size: 22px;
        color: #333;
        cursor: pointer;
      }
    
      .profile-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; /* Make it circular */
      }
          .signup-link {
            color: #c69e59;
            text-decoration: none;
            font-weight: bold;
          }
          .signup-link:hover {
            text-decoration: underline;
          }
          .create-account-container {
            width: 100%;
            max-width: 800px;
            padding: 2.5rem;
            background-color: #ffffff;
            border-radius: 15px;
            box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
            margin-top: 20px;
            margin-bottom: 20px;
          }
          .account-form {
            margin-top: 1rem;
          }
          .form-label {
            display: block;
            font-weight: 600;
            margin-bottom: 0.3rem;
            color: #333;
            text-align: left;
          }
          .submit-btn {
            width: 30%;
            background-color: #c69e59;
            border: none;
            padding: 0.75rem;
            font-weight: bold;
            font-size: 1rem;
            margin-top: 1rem;
            margin-left: auto;
            margin-right: auto;
            display: block;
          }
          .submit-btn:hover {
            background-color: #b07e46;
          }
          .checkbox-label-spacing .form-check-input {
           text-align: left;
          }
          .checkbox-label-spacing .form-check-label {
            margin-top: 0; /* Ensures the label aligns with the checkbox */
          }
        `}</style>
      </div>
    </Container>
  );
}

export default Register;
