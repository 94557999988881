import { db, storage } from "../../../firebaseConfig"; 
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const uploadImage = async (file, folder) => {
  if (!file) throw new Error("No file provided");

  const storageRef = ref(storage, `${folder}/${Date.now()}-${file.name}`);
  const snapshot = await uploadBytes(storageRef, file);
  return await getDownloadURL(snapshot.ref);
};

const saveBlog = async ({ title, description, longDescription, blogImage, contentImages }) => {
  if (!title || !description || !longDescription) throw new Error("All fields are required");

  const blogData = {
    title,
    description,
    longDescription,
    blogImage, // Include the mainImage URL here if it's not commented out in the form
    contentImages, // Include contentImages array if necessary
    createdAt: new Date().toISOString(),
  };

  try {
    const blogCollection = collection(db, "blogs");
    await addDoc(blogCollection, blogData);  // Adds the document to Firestore
    console.log("Blog saved successfully");
  } catch (error) {
    console.error("Error saving blog:", error.message);
  }
};


export { uploadImage, saveBlog };
