import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { fetchBlogs } from '../../controllers/admin_pannel/blogs/fetchBlogs';

const BlogsComponent = () => {
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); 
  const blogsPerPage = 6;
  const navigate = useNavigate();

  useEffect(() => {
    const loadBlogs = async () => {
      try {
        const fetchedBlogs = await fetchBlogs();
        setBlogs(fetchedBlogs);
      } catch (error) {
        console.error("Error loading blogs:", error);
      } finally {
        setLoading(false);  
      }
    };

    loadBlogs();
  }, []);

  const totalPages = Math.ceil(blogs.length / blogsPerPage);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const currentBlogs = blogs.slice(
    (currentPage - 1) * blogsPerPage,
    currentPage * blogsPerPage
  );

  const handleDetailsClick = (blog) => {
    navigate("/blogDetails", { state: { blog } });
  };

  return (
    <>
      <Container fluid className="py-5" style={{ backgroundColor: '#f8f3e3' }}>
        <Container>
          <Row className="text-center mb-4">
            <Col>
              <h1 className="fw-bold">Blogs</h1>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid>
        <Container className="py-5">
          <style>
            {`
              .blogs-title {
                text-align: center;
                font-weight: bold;
                font-size: 2rem;
              }
              .card-title {
                text-align: left;
                font-size: 1.2rem;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
              .card-text {
                text-align: justify;
                font-size: 0.95rem;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
              .custom-card {
                border: none;
                cursor: pointer;
              }
              .custom-card:hover {
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
              }
              .custom-card-img {
                max-height: 200px;
                object-fit: cover;
              }
              .pagination-container {
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .pagination-button {
                background-color: #EDD086;
                border: none;
                color: white;
                border-radius: 5px;
                height: 40px;
                max-width: 70px;
              }
              .pagination-button:hover {
                background-color: #d5b071;
              }
            `}
          </style>
          <Row className="justify-content-center">
            {loading ? (
              <Col>
                <h5>Loading...</h5> 
              </Col>
            ) : (
              currentBlogs.map((blog, index) => (
                <Col md={4} className="mb-4" key={index}>
                  <Card
                    className="shadow-sm custom-card"
                    onClick={() => handleDetailsClick(blog)}
                  >
                    <Card.Img
                      variant="top"
                      src={blog.blogImage || '/images/default-blog.png'}
                      className="custom-card-img"
                    />
                    <Card.Body>
                      <Card.Title>{blog.title}</Card.Title>
                      <Card.Text>{blog.description}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            )}
          </Row>
          <div className="pagination-container mt-4">
            <button
              className="pagination-button"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="mx-3">
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="pagination-button"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </Container>
      </Container>
    </>
  );
};

export default BlogsComponent;
