import React, { useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Questions = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleQuestion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const questions = [
        { id: 1, title: 'Purchase Your Ticket & Receive Your Business Idea', content: 'Start your entrepreneurial journey by purchasing a ticket on our website. After your purchase, you’ll receive a carefully crafted, market-ready business idea that’s ready to implement.' },
        { id: 2, title: 'Choose: Go Solo or Collaborate', content: 'Evaluate the idea and decide if it aligns with your vision. If the idea is a perfect fit, you can choose to work on it independently. If it doesn’t meet your expectations, you can decline it or opt for collaboration.' },
        { id: 3, title: 'Collaborate for Success', content: 'If you choose to collaborate, the idea will be shared with three other entrepreneurs in our network. They will pitch their approach, and you’ll select the ideal partner to work with on the concept.' },
        { id: 4, title: 'Get Expert Guidance & Build Your Business', content: 'Whether you decide to work independently or partner up, TL Enterprise provides the mentorship and support you need. We offer the expertise, while you bring the dedication and drive to turn your business into a success.' },
    ];

    return (
        <Container fluid>
            <Container>
                <Row className="mb-4">
                    <Col>
                        <h1 className="fw-bold text-center">How Does It Work?</h1>
                        <p className="text-center">Plug-and-Play Business Model - TL Enterprise Institute</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        {questions.map((question, index) => (
                            <Card  key={question.id} className="mb-3">
                                <Card.Body style={{ backgroundColor: '#FFFBEF'}} className="d-flex">
                                    <div>
                                        <span
                                            className="fw-bold me-2"
                                            style={{
                                                display: 'inline-block',
                                                height: '30px',
                                                width: '30px',
                                                borderRadius: '50%',
                                                backgroundColor: '#D9AB4A',
                                                textAlign: 'center',
                                                lineHeight: '30px',
                                                color: 'white',
                                            }}
                                        >
                                            {index + 1}
                                        </span>
                                        {question.title}
                                    </div>
                                    <div onClick={() => toggleQuestion(index)} style={{ cursor: 'pointer' }} className="ms-auto">
                                        {openIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                                    </div>
                                </Card.Body>
                                {openIndex === index && (
                                    <Card.Body style={{ textAlign: 'left' }} >
                                        <p className="mb-0">{question.content}</p>
                                    </Card.Body>
                                )}
                            </Card>
                        ))}
                    </Col>
                    <Col md={6} className="d-flex justify-content-center align-items-start">
                        <img
                            src="/images/questions.png"
                            alt="How Does It Work"
                            width='100%'
                            height='80%'
                            className="rounded"
                        />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default Questions;
