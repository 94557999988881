import React, { useState, useEffect } from "react";
import { Table, Button, Form, Modal, Spinner } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { collection, setDoc, onSnapshot, deleteDoc, doc } from "firebase/firestore";
import { db } from "../../firebaseConfig"; // Adjust the path if necessary
import Notification from "../pop_up/notification";
import DeleteModal from "../pop_up/deleteConfirmation";

const CategorySettings = () => {
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [loading, setLoading] = useState(true);
  const [categoryError, setCategoryError] = useState(""); // Error state for category field
  const [notification, setNotification] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State to show modal
  const [ideaToDelete, setIdeaToDelete] = useState(null);

  const handleShowFilterPopup = () => setShowFilterPopup(true);
  const handleCloseFilterPopup = () => {
    setNewCategory("");
    setCategoryError(""); // Reset error message when closing
    setShowFilterPopup(false);
  };

  const handleAddCategory = async () => {
    if (!newCategory.trim()) {
      setCategoryError("Category is required."); // Show error if the field is empty
      return;
    } else {
      setCategoryError(""); // Clear error if input is valid
    }

    try {
      // Create a new document reference with a generated ID
      const docRef = doc(collection(db, "categories"));
      const docId = docRef.id;

      // Set the document with the name and docId
      await setDoc(docRef, { name: newCategory, docId, timestamp: new Date() });

      setNewCategory("");
      handleCloseFilterPopup();
      setNotification("Category added successfully!"); 
    } catch (error) {
      console.error("Error adding category: ", error);
      setNotification("Category ", error); 
    }
  };
  const handleDeleteIdea = (id) => {
    setIdeaToDelete(id); // Set the niche ID to delete
    setShowDeleteModal(true); // Show the delete confirmation modal
  };

  const confirmDelete = async () => {
    try {
      const nicheRef = doc(db, "categories", ideaToDelete); // Reference the document
      await deleteDoc(nicheRef); // Delete from Firestore
      setNotification("Category deleted successfully!");
      setShowDeleteModal(false); // Close the modal
    } catch (error) {
      console.error("Error deleting category: ", error);
      setNotification("Error deleting category.");
    }
  };

  const cancelDelete = () => {
    setShowDeleteModal(false); // Close the modal without deleting
    setIdeaToDelete(null); // Reset the niche to delete
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "categories"),
      (snapshot) => {
        const fetchedCategories = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // Sort categories by timestamp (newest first)
        const sortedCategories = fetchedCategories.sort(
          (a, b) => b.timestamp?.toDate() - a.timestamp?.toDate()
        );
        setCategories(sortedCategories);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching categories: ", error);
        setLoading(false);
        setNotification("Category !", error); 
      }
    );
  
    return () => unsubscribe();
  }, []);
  

  return (
    <>
      <style type="text/css">
        {`
          .category-settings-container {
            max-width: 800px;
            margin: 0 auto;
            padding: 20px;
            background: #fff;
            border-radius: 10px;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          }
          @media (min-width: 992px) {
            .category-settings-container {
              margin-left: 242px;
              padding-right: 20px;
            }
          }
          @media (max-width: 991px) {
            .category-settings-container {
              padding-left: 8px;
              padding-right: 8px;
            }
          }
          .settings-title {
            font-size: 40px;
            font-weight: bold;
            margin-bottom: 30px;
            color: #000;
          }
          .category-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
          }
          .category-title {
            font-size: 24px;
            font-weight: 700;
            color: #000;
          }
          .add-category-btn {
            background-color: #d6a63e;
            color: #fff;
            border: none;
            border-radius: 30px;
          }
          .add-category-btn:hover {
            background-color: #c59532;
          }
          .category-table {
            width: 100%;
          }
          .category-table th {
            font-weight: 600;
            color: #000;
          }
          .category-table td {
            vertical-align: middle;
          }
          .delete-btn {
            background: none;
            border: none;
            color: #555;
          }
          .delete-btn:hover {
            color: #d9534f;
          }
          .icon-trash-alt {
            color: #d6a63e;
            margin-right: 8px;
            cursor: pointer;
          }
          .filter-popup {
            padding: 20px;
            background-color: #fff;
            border-radius: 10px;
            text-align: center;
          }
          .filter-popup h4 {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 20px;
          }
          .filter-popup .form-control {
            border-radius: 30px;
            padding: 10px 15px;
            border: 1px solid #ccc;
            font-size: 16px;
          }
          .filter-popup .save-button {
            background-color: #d6a63e;
            color: #fff;
            border: none;
            border-radius: 30px;
            padding: 10px 30px;
            font-size: 16px;
            font-weight: bold;
            margin-top: 20px;
          }
          .filter-popup .save-button:hover {
            background-color: #c59532;
          }
        `}
      </style>

      <div className="category-settings-container">
        <h1 className="settings-title">Settings</h1>
        <div className="category-header">
          <h2 className="category-title">Category</h2>
          <Button className="add-category-btn" onClick={handleShowFilterPopup}>
            Add Category
          </Button>
        </div>

        {loading ? (
          <div className="loading-text">
            <Spinner animation="border" /> Loading...
          </div>
        ) : (
          <Table bordered hover className="category-table">
            <thead>
              <tr>
                <th>
                  <Form.Check type="checkbox" />
                </th>
                <th>Category Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category) => (
                <tr key={category.id}>
                  <td>
                    <Form.Check type="checkbox" />
                  </td>
                  <td>{category.name}</td>
                  <td>
                    <FaTrashAlt
                      className="icon-trash-alt"
                      onClick={() => handleDeleteIdea(category.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
            <DeleteModal
              show={showDeleteModal}
              onHide={cancelDelete}
              onConfirm={confirmDelete}
              message="Are you sure you want to delete this niche?"
            />
          </Table>
        )}
      </div>

      <Modal show={showFilterPopup} onHide={handleCloseFilterPopup} centered>
        <Modal.Body>
          <div className="filter-popup">
            <h4>Category</h4>
            <Form>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Technology"
                  className="form-control"
                  value={newCategory}
                  onChange={(e) => {
                    setNewCategory(e.target.value);
                    setCategoryError(""); // Clear error on input
                  }}
                />
                {categoryError && (
                  <div style={{ color: "red", marginTop: "5px", fontSize: "14px" }}>
                    {categoryError}
                  </div>
                )}
              </Form.Group>
              <Button className="save-button" onClick={handleAddCategory}>
                Save
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
      {notification && (
        <Notification
          text={notification}
          onClose={() => setNotification(null)}
        />
      )}
    </>
  );
};

export default CategorySettings;
