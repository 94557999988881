import React, { useState, useRef, useMemo } from "react";
import { Container, Row, Form, Button } from "react-bootstrap";
import { FaCamera, FaPlusCircle } from "react-icons/fa";
import ReactQuill from "react-quill";
import { uploadImage, saveBlog } from "../../controllers/admin_pannel/blogs/addBlogs";
import "react-quill/dist/quill.snow.css";
import Notification from "../../components/pop_up/notification";
import { useNavigate } from "react-router-dom";

const AddBlogs = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [blogImage, setBlogImage] = useState(null);
  const [mainImagePreview, setMainImagePreview] = useState(null);
  const [errors, setErrors] = useState({});
  const quillRef = useRef(null);
  const [notification, setNotification] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const validateFields = () => {
    const newErrors = {};
    if (!title.trim()) newErrors.title = "Title is required.";
    if (!description.trim()) newErrors.description = "Description is required.";
    if (!longDescription.trim()) newErrors.longDescription = "Long description is required.";
    if (!blogImage) newErrors.blogImage = "Main image is required.";
    return newErrors;
  };

  const handleMainImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBlogImage(file);
      setMainImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fieldErrors = validateFields();

    if (Object.keys(fieldErrors).length > 0) {
      setErrors(fieldErrors);
      return;
    }

    setErrors({});
    setLoading(true);

    try {
      const mainImageUrl = blogImage
        ? await uploadImage(blogImage, "mainImages")
        : "https://www.ryrob.com/wp-content/uploads/2021/11/iStock-496848472-1024x1024.jpg";

      await saveBlog({
        title,
        description,
        longDescription,
        blogImage: mainImageUrl,
        contentImages: [],
      });

      setNotification("Blog saved successfully!");
      setTimeout(() => navigate("/admin/blogManagement"), 2000);
    } catch (error) {
      console.error("Error saving blog:", error.message);
      setNotification("An error occurred while saving the blog.");
    } finally {
      setLoading(false);
    }
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ font: [] }],
          [{ size: [] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ script: "sub" }, { script: "super" }],
          [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
          ["link", "image", "video"],
          ["clean"],
        ],
      },
    }),
    []
  );

  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "clean",
  ];

  return (
    <div className="idea-submission-wrapper">
      <style jsx="true">{`
        .idea-submission-wrapper {
          max-width: 1000px;
          margin-left: 240px;
          padding: 20px;
        }
        @media (max-width: 900px) {
          .idea-submission-wrapper {
            margin-left: 2px;
            margin-right: 2px;
          }
        }

        .form-container {
          background: #fff;
          width: 100%;
          padding: 30px;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        .form-title {
          text-align: center;
          margin-bottom: 20px;
          font-weight: 700;
        }

        .upload-photo {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background: #e9ecef;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          position: relative;
          cursor: pointer;
        }

        .upload-photo img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }

        .upload-photo .camera-icon {
          font-size: 30px;
          color: #6c757d;
        }

        .upload-photo .plus-icon {
          position: absolute;
          top: -8px;
          right: -8px;
          font-size: 18px;
          color: #c69e59;
        }

        .upload-photo-text {
          display: block;
          text-align: center;
          margin-top: 5px;
          color: #6c757d;
        }

        .form-label {
          font-weight: 500;
          margin-bottom: 6px;
          display: block;
          text-align: left;
        }

        .form-control,
        .form-select {
          border-radius: 8px;
        }

        .text-danger {
          text-align: left !important;
        }

        .save-button {
          width: 200px;
          height: 40px;
          background: #c69e59;
          border: none;
          border-radius: 28px;
          transition: background 0.3s ease;
          margin: 20px auto 0;
          display: block;
        }

        .save-button:hover {
          background: #a88247;
        }
      `}</style>
      <Container>
        <Row className="justify-content-center">
          <div className="form-container">
            <h3 className="form-title">Add Blog</h3>
            <Form onSubmit={handleSubmit}>
              <div className="mb-3 text-center">
                <div className="upload-photo">
                  {mainImagePreview ? (
                    <img src={mainImagePreview} alt="Preview" />
                  ) : (
                    <>
                      <FaCamera className="camera-icon" />
                      <FaPlusCircle className="plus-icon" />
                    </>
                  )}
                  <input
                    type="file"
                    onChange={handleMainImageChange}
                    style={{
                      position: "absolute",
                      width: "100px",
                      height: "100px",
                      opacity: 0,
                      cursor: "pointer",
                    }}
                  />
                </div>
                <small className="upload-photo-text">Upload Main Photo</small>
                {errors.blogImage && (
                  <div className="text-danger mt-1">{errors.blogImage}</div>
                )}
              </div>

              <Form.Group className="mb-3">
                <Form.Label className="form-label">Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                {errors.title && <div className="text-danger mt-1">{errors.title}</div>}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="form-label">Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  placeholder="Enter description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                {errors.description && <div className="text-danger mt-1">{errors.description}</div>}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="form-label">Long Description</Form.Label>
                <ReactQuill
                  ref={quillRef}
                  value={longDescription}
                  onChange={setLongDescription}
                  theme="snow"
                  placeholder="Write your long description here..."
                  modules={modules}
                  formats={formats}
                />
                {errors.longDescription && (
                  <div className="text-danger mt-1">{errors.longDescription}</div>
                )}
              </Form.Group>

              <Button type="submit" className="save-button" disabled={loading}>
                {loading ? "Processing..." : "Save"}
              </Button>
            </Form>
          </div>
        </Row>
      </Container>
      {notification && <Notification text={notification} onClose={() => setNotification(null)} />}
    </div>
  );
};

export default AddBlogs;
