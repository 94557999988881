import React from "react";
import { Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UserService from "../../controllers/currentUserId"; 
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebaseConfig"; 

const Hero = () => {
  const navigate = useNavigate();

  const handleGetStartedClick = async () => {
    try {
      const userId = UserService.getCurrentUserId();

      if (userId) {
        // If the user is logged in, check if they have a successful 'Account Creation' transaction
        const transactionsRef = collection(db, "transactions");
        const q = query(
          transactionsRef,
          where("userId", "==", userId),
          where("status", "==", "success"),
          where("type", "==", "Account Creation")
        );

        // Fetch the transaction data from Firestore
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          // If a successful transaction is found, navigate to home
          navigate("/dashboard");
        } else {
          // If no such transaction is found, navigate to payment page
          navigate("/accountCreationPayment");
        }
      } else {
        // If userId is not available, navigate to register page
        navigate("/register");
      }
    } catch (error) {
      // In case of error (e.g., network or Firestore issue), navigate to register page
      console.error("Error handling user navigation:", error);
      navigate("/register");
    }
  };

  return (
    <section className="hero-section">
      <style>{`
        .hero-section {
          padding: 60px 0;
          text-align: center;
          background-color: #ffffff;
          color: #333;
        }

        .hero-heading {
          font-weight: bold;
          font-size: 2.5rem;
          color: #c69e59;
        }
        .hero-threeHeading {
          font-weight: bold;
          font-size: 2.2rem;
          color: black;
        }

        .hero-subheading {
          font-weight: 600;
          font-size: 1.2rem;
          color: #000000;
          margin-bottom: 20px;
        }

        .hero-description {
          font-size: 1rem;
          color: #666;
          margin-top: 10px;
          margin-bottom: 30px;
        }

        .hero-buttons .btn-get-started {
          background-color: #c69e59;
          color: white;
          font-weight: 600;
          padding: 10px 30px;
          border-radius: 20px;
          margin-right: 15px;
          border: none;
        }

        .hero-buttons .btn-get-started:hover {
          background-color: #a48045;
        }

        .hero-buttons .btn-how-it-works {
          background-color: #ffffff;
          color: #c69e59;
          font-weight: 600;
          padding: 10px 30px;
          border-radius: 20px;
          border: 1px solid #c69e59;
        }

        .hero-buttons .btn-how-it-works:hover {
          background-color: #f3e8d9;
          color: #a48045;
          border-color: #a48045;
        }

        @media (max-width: 768px) {
          .hero-heading {
            font-size: 2rem;
          }
          .hero-threeHeading {
            font-size: 1.8rem;
          }
          .hero-subheading {
            font-size: 1rem;
          }
          .hero-description {
            font-size: 0.9rem;
          }
          .hero-buttons .btn-get-started, .hero-buttons .btn-how-it-works {
            width: 100%;
            margin-bottom: 10px;
          }
        }
      `}</style>

      <Container>
        <span className="brand-text-tl">TL</span>
        <span className="brand-text-institute">Enterprise Institute</span>
        <h1 className="hero-heading">Pitched to investors, worked out,</h1>
        <h2 className="hero-threeHeading">
        With access to a team of freelancers, 97% profitable, 3% your effort, motivation and determination.
        </h2>
        <p className="hero-description">All the ideas are Plug and Play.</p>
        <div className="hero-buttons">
          <Button onClick={handleGetStartedClick} className="btn-get-started">
            Get Started
          </Button>
          <Button
            href="/aboutUs#howWorks"
            className="btn-how-it-works"
            as="a"
          >
            How it works
          </Button>
        </div>
      </Container>
    </section>
  );
};

export default Hero;
