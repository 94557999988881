import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import UserService from '../../controllers/currentUserId';
import { db, storage, auth } from '../../firebaseConfig'; 
import { doc, updateDoc, getDoc } from 'firebase/firestore'; 
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; 
import { updateEmail, updatePassword, signInWithEmailAndPassword } from 'firebase/auth';
import { fetchCategories } from '../../controllers/categoriesController';
import { fetchNiches } from '../../controllers/nicheController';
import Notification from '../../components/pop_up/notification';
import {useNavigate } from 'react-router-dom';

function UserProfilePage() {
  const [categories, setCategories] = useState([]);
  const [niches, setNiches] = useState([]);
  const [filteredNiches, setFilteredNiches] = useState([]);
  const [userData, setUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [notification, setNotification] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false); 
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    address: '',
    country: '',
    nationality: '',
    investmentRange: '999 - 3000 $',
    category: '',
    categoryId: '',
    niche: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryData = await fetchCategories();
        setCategories(categoryData);

        const nicheData = await fetchNiches();
        setNiches(nicheData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (formData.categoryId) {
      const filtered = niches.filter(
        (niche) => niche.categoryId === formData.categoryId
      );
      setFilteredNiches(filtered);

      if (!isEditing) {
        setFormData((prev) => ({
          ...prev,
          niche: prev.niche || '',
        }));
      } else if (filtered.length === 0) {
        setFormData((prev) => ({ ...prev, niche: '' }));
      }
    } else {
      setFilteredNiches([]);
    }
  }, [formData.categoryId, niches, isEditing]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'category') {
      const selectedCategory = categories.find((cat) => cat.id === value);
      setFormData((prevData) => ({
        ...prevData,
        category: selectedCategory ? selectedCategory.name : '',
        categoryId: selectedCategory ? selectedCategory.id : '',
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const [imageSrc, setImageSrc] = useState(null);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const userId = UserService.getCurrentUserId();
        const imageRef = ref(storage, `profileImages/${userId}`);
        await uploadBytes(imageRef, file);

        const downloadURL = await getDownloadURL(imageRef);
        setImageSrc(downloadURL);

        const userRef = doc(db, 'users', userId);
        await updateDoc(userRef, { profileImage: downloadURL });
        setNotification("Profile image updated in Firestore"); 
        setTimeout(() => navigate('/dashboard'), 2000);
      } catch (error) {
        console.error('Error uploading image:', error);
        setNotification(error); 
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const userId = UserService.getCurrentUserId();
      try {
        const userRef = doc(db, 'users', userId);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setUserData(data);
          setFormData({
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            password: data.password,
            address: data.address,
            country: data.country,
            nationality: data.nationality,
            investmentRange: data.investmentRange,
            category: data.category,
            categoryId: data.categoryId || '',
            niche: data.niche,
          });
          if (data.profileImage) {
            setImageSrc(data.profileImage);
          }
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
        setNotification(error); 
      }
    };
    fetchData();
  }, []);

  const handleSave = async () => {
    try {
      setIsProcessing(true); 
      const user = auth.currentUser; // Get the currently authenticated user
      const userId = UserService.getCurrentUserId();
      let reAuthUser = await signInWithEmailAndPassword(auth, userData.email, userData.password)
      const userRef = doc(db, 'users', userId);

      // Update Firebase Auth Email
      if (formData.email !== user.email) {
        await updateEmail(reAuthUser.user, formData.email);
        console.log('Email updated in Firebase Auth');
      }

      // Update Firebase Auth Password
      if (formData.password) {
        await updatePassword(reAuthUser.user, formData.password);
        console.log('Password updated in Firebase Auth');
      }
      await signInWithEmailAndPassword(auth, formData.email, formData.password);

      // Update Firestore Data
      await updateDoc(userRef, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        address: formData.address,
        country: formData.country,
        nationality: formData.nationality,
        investmentRange: formData.investmentRange,
        category: formData.category,
        categoryId: formData.categoryId,
        niche: formData.niche,
      });

      setIsEditing(false); // Disable editing after saving
      setNotification("Profile updated successfully!"); 
      setTimeout(() => navigate('/dashboard'), 2000);
    } catch (error) {
      console.error('Error updating user profile:', error);
      alert(error);
      setNotification(error); 
    }
    finally {
      setIsProcessing(false); // Stop processing
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  if (!userData) {
    return <div>Loading...</div>;
  }
  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <div className="create-account-container">
        <div className="d-flex align-items-center mb-4">
          <h2 className="profile-header">Profile</h2>
          <FaEdit className="edit-icon ms-3" onClick={handleEditClick} />
        </div>
        <Container
          className="d-flex flex-column align-items-center justify-content-center"
          style={{
            width: '70px',
            height: '70px',
            border: '1px solid black',
            position: 'relative',
            textAlign: 'center',
            overflow: 'hidden',
            borderRadius: '50%', // Optional for circular container
            backgroundColor: '#f0f0f0', // Light gray background
          }}
        >
          {imageSrc ? (
            <img
              src={imageSrc}
              alt="Profile"
              style={{ width: '60px', height: '60px', objectFit: 'cover' }}
            />
          ) : (
            <i
              className="bi bi-person-circle"
              style={{ fontSize: '40px', color: '#6c757d' }} // Bootstrap icon for a user
            ></i>
          )}

          <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            id="upload-input"
            onChange={handleImageUpload}
            disabled={!isEditing}
          />
          {isEditing && (
            <Button
              variant="success"
              size="sm"
              style={{
                position: 'absolute',
                bottom: 16,
                left: 0,
                width: '100%',
                height: '30px',
              }}
              onClick={() => document.getElementById('upload-input').click()}
            >
              Add
            </Button>
          )}
        </Container>
        <Form className="account-form">
          <Row>
            <Col md={6}>
              <Form.Group controlId="firstName">
                <Form.Label className="form-label">First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your first name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="lastName">
                <Form.Label className="form-label">Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your last name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="email">
                <Form.Label className="form-label">Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="password">
                <Form.Label className="form-label">Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="address">
                <Form.Label className="form-label">Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="country">
                <Form.Label className="form-label">Country</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="nationality">
                <Form.Label className="form-label">Nationality</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your nationality"
                  name="nationality"
                  value={formData.nationality}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="investmentRange">
                <Form.Label className="form-label">Investment Range</Form.Label>
                <Form.Control
                  as="select"
                  name="investmentRange"
                  value={formData.investmentRange}
                  onChange={handleChange}
                  disabled={!isEditing}
                >
                  <option>999 - 3000 $</option>
                  <option>3001 - 5000 $</option>
                  <option>5001 - 10000 $</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label className="form-label">Category</Form.Label>
                <Form.Select
                  name="category"
                  value={categories.find((cat) => cat.name === formData.category)?.id || ""}
                  onChange={handleChange}
                  disabled={!isEditing}
                >
                  <option value="" disabled>
                    Select a category
                  </option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label className="form-label">Niche</Form.Label>
                <Form.Select
                  name="niche"
                  value={formData.niche}
                  onChange={handleChange}
                  disabled={!isEditing}
                >
                  <option value="" disabled>
                    Select a niche
                  </option>
                  {filteredNiches.map((niche) => (
                    <option key={niche.id} value={niche.niche}>
                      {niche.niche}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

            </Col>
          </Row>
          {isEditing && (
            <Button
            onClick={handleSave}
            variant="primary"
            type="button"
            className="submit-btn"
            disabled={isProcessing} // Disable the button while processing
          >
            {isProcessing ? "Processing..." : "Save"} {/* Show dynamic text */}
          </Button>
          )}
        </Form>

        <style jsx>{`
        .signup-link {
          color: #c69e59;
          text-decoration: none;
          font-weight: bold;
        }
        .profile-image {
          width: 60px;
          height: 60px;
          object-fit: cover;
          border-radius: 50%; 
        }
        .signup-link:hover {
          text-decoration: underline;
        }
        .create-account-container {
          width: 100%;
          max-width: 800px;
          padding: 2.5rem;
          background-color: #ffffff;
          border-radius: 15px;
          box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
          margin-top: 20px;
          margin-bottom: 20px;
        }
        .account-form {
          margin-top: 1rem;
        }
        .form-label {
          display: block;
          font-weight: 600;
          margin-bottom: 0.3rem;
          color: #333;
          text-align: left;
        }
        .submit-btn {
          width: 30%;
          background-color: #c69e59;
          border-radius: 30px;
          border: none;
          padding: 0.75rem;
          font-weight: bold;
          font-size: 1rem;
          margin-top: 1rem;
          margin-left: auto;
          margin-right: auto;
          display: block;
        }
        .edit-icon {
          font-size: 1.2rem;
          color: #c69e59;
          cursor: pointer;
        }

        .edit-icon:hover {
          color: #b07e46;
        }
        .submit-btn:hover {
          background-color: #b07e46;
        }
      `}</style>
      </div>
      {notification && (
        <Notification
          text={notification}
          onClose={() => setNotification(null)}
        />
      )}
    </Container>
  );
}

export default UserProfilePage;
